export default {
  counter_agent_inn: "ИНН",
  counter_agent_name: "Наименование организации",
  counter_agent_person_name: "Наименование ИП",
  counter_agent_json: {
    country_name: "Страна",
    country_letter: "Страна",
    postal_index: "Индекс",
    region: "Область/Район",
    city: "Город/Деревня",
    street_house: "Адрес",
    phone: "Телефон"
  }
}