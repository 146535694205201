<template>
  <div class="control__message">
    <div class="control__message-icon">
      <img
        :src="item.img"
      >
    </div>
    <div class="control__message-content">
      {{ item.graph }}
      <!-- ЕСТЬ ITEMS-->
      <span
        v-if="'items' in item"
      >{{ item.name }}</span> 
      <span
        v-else
        class="element__link"
        @click="beforeMove({path:item.path})"
      >{{ item.name }}</span>
      {{ item.message.text }}
      <!-- ЕСТЬ ITEMS-->
      <div
        v-if="'items' in item"
        class="element__links"
      >
        <!-- МЕНЬШЕ 5 ЭЛЕМЕНТОВ В СПИСКЕ-->
        <template v-if="item.items.length <= 5">
          <span
            v-for="(element, sidx) in item.items"
            :key="`msg-${idx}-s-${sidx}`"
            class="mx-1 "
            :class="getItemClass(element)"
            @click="beforeMove({path:getPathFromElement(element), index:getIndexFromElement(element)})"
          >{{ getIndexFromElement(element) }}<span class="transparent">,</span>
          </span>
        </template>
        <!-- БОЛЬШЕ 5 ЭЛЕМЕНТОВ В СПИСКЕ-->
        <template v-else>
          <span
            v-for="(element, sidx) in item.items.slice(0,5)"
            :key="`msg-${idx}-s-${sidx}`"
            class="mx-1 "
            :class="getItemClass(element)"
            @click="beforeMove({path:getPathFromElement(element), index:getIndexFromElement(element)})"
          >{{ getIndexFromElement(element) }}<span class="transparent">,</span>
          </span>
          <span
            v-if="item.extended === false"
            class="cursor-pointer"
            @click="item.extended = true"
          >
            ...
          </span>
          <template v-else>
            <span
              v-for="(element, sidx) in item.items.slice(5, item.items.length - 1 )"
              :key="`msg-${idx}-s-${sidx}-extended`"
              class="mx-1"
              :class="getItemClass(element)"
              @click="beforeMove({
                path:getPathFromElement(element),
                index:getIndexFromElement(element)
              })"
            >{{ getIndexFromElement(element) }}<span class="transparent">,</span>
            </span>
          </template>
                   
          <span
            class="mx-1"
            :class="getCurrentSelectedChild === lastControlItem ? 'element__link_selected' : 'element__link'"
            @click="beforeMove({
              path:getPathFromElement(lastControlItem),
              index:getIndexFromElement(lastControlItem)
            })"
          >{{ getIndexFromElement(lastControlItem) }}
          </span>
        </template>
        <v-tooltip
          v-if="'items' in item"
          right
        >
          <template #activator="{ on, attrs }">
            <v-btn
              class="px-0 ml-2"
              color="#DEDEDE"
              min-width="20px"
              max-width="20px"
              height="20px"
              v-bind="attrs"
              v-on="on"
              @click="copyListItems"
            >
              <v-icon
                x-small
              >
                mdi-content-copy
              </v-icon>
            </v-btn>
          </template>
          Копировать список
        </v-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
import {moveTo, getIndexFromElement, getPathFromElement} from '@/helpers/control'
export default {
  props:{
    item:{
      type:Object,
      required:true
    },
    idx:{
      type:Number,
      required:true
    },
    selected:{
      required:false,
      default:null,
      validator: (prop) => typeof prop === 'object' || prop === null
    }
  },
  computed:{
    getCurrentSelectedChild(){
      // Если не cовпадает с item - то false,
      // иначе - сорт-индекс выбранного элемента из массива сорт индексов
      return this.selected?.rootElement === this.item ? this.selected?.childSortIndex : false
    },
    lastControlItem(){
      return this.item.items[this.item.items.length -1]
    }
  },
  methods:{
    beforeMove({path, index}){
      if (index)
        this.$emit("select", {rootElement: this.item, childSortIndex: index});

      this.moveTo({path})
    },
    getItemClass(element){
      return this.getCurrentSelectedChild === getIndexFromElement(element) ? 'element__link_selected' : 'element__link'
    },
    moveTo,
    getIndexFromElement,
    getPathFromElement,
    copyListItems(){
      const indexes = this.item.items.map(obj => {
        const [index] = Object.values(obj)
        return index
      })
      const result = indexes.join(',').replace(/ /g, '')
      this.$copyText(result)
    }
  }
}
</script>
<style scoped>
.control__message{
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;
}
.control__message-icon{
  display: flex;
  align-items: center;
  justify-content: center;
}
.control__message-icon img{
  width: 16px;
}
.control__message-content{
 margin: 0 0 0 10px;
}
.cursor-pointer{
  cursor: pointer;
}
.element__links{
  word-break: break-word;
}
.element__link{
  border-bottom: 1px solid white;
  cursor: pointer;
}
.element__link_selected{
  cursor: pointer;
}
.transparent{
  color:transparent;
}
</style>