import {highlightField} from "@/helpers/control";
import {deleteNulls} from "@/helpers/inputs";
import {getReferenceAsync, waitAsyncDecision, waitResultOrDie} from "@/helpers/async-component";
import {updateSelectedPto} from "@/helpers/documents";

export default {
  data: () => ({
    showComponent: false,
    activeAction: 0,
    activeComponentParams: {
      text: "",
    },
    asyncDecision: {
      resolve:null,
      reject:null
    }
  }),
  computed:{
    activeComponent() {
      return this.activeAction ? this.actions[this.activeAction].component : null;
    },
    visibleActions() {
      return this.actions
        .filter((i) => i.visible === true)
        .map((i) => {
          if (i.action === "setControlled") {
            return {
              ...i,
              name: this.selected.controlled
                ? "Отключить уведомление"
                : "Включить уведомление",
              color: this.selected.controlled ? "red" : "",
              icon: this.selected.controlled ? "mdi-bell-off" : "mdi-bell",
            };
          } else if (i.action === "setFavorite") {
            return {
              ...i,
              name: this.selected.favorite
                ? "Убрать из избранных"
                : "Добавить в избранные",
              color: this.selected.favorite ? "orange" : "",
            };
          } else {
            return i;
          }
        });
    },
  },
  watch:{
    showComponent(value){
      if(!value) this.unsetAsyncComponent()
    },
  },
  methods: {
    waitAsyncDecision,
    getReferenceAsync,
    waitResultOrDie,
    highlightField,
    deleteNulls,
    updateSelectedPto,
    unsetAsyncComponent(){
      this.activeAction = null
    },
    setControl(controlResult) {
      this.$store.commit(`${this.namespace}/SET_CONTROL`, controlResult);
    },
    setLoading(action, loading) {
      const element = this.actions.find((i) => i.action === action);
      const index = this.actions.indexOf(element);
      this.actions.splice(index, 1, {
        ...element,
        loading,
      });
    },
    showDocumentControlPto() {
      this.activeAction = this.getActionIndex("showDocumentControlPto");
      this.showComponent = true;
      return Promise.resolve()
        .then(this.waitAsyncDecision)
        .then(this.waitResultOrDie)
    },
    showDocumentControlResponse() {
      this.activeAction = this.getActionIndex("showDocumentControlResponse");
      this.showComponent = true;
      return Promise.resolve()
        .then(this.waitAsyncDecision)
        .then(this.waitResultOrDie)
    },
    showXsdControlResponse({message = ""}) {
      this.activeComponentParams.text = message;
      this.activeAction = this.getActionIndex("showXsdControlResponse");
      this.showComponent = true;
    },
    callFunction(func, ...args) {
      func.length ? this[func](...args) : false;
    },
    getActionIndex(name) {
      const index = this.actions.findIndex((i) => i.action === name);
      if (index === -1) throw new Error("Не найден обработчик");
      return index;
    },
    getItemDeclarationType(type) {
      switch (type) {
      case "DTS1":
        return "ДТС-1";
      case "DTS2":
        return "ДТС-2";
      default:
        return "";
      }
    }
  }
}