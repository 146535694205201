var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"control__message"},[_c('div',{staticClass:"control__message-icon"},[_c('img',{attrs:{"src":_vm.item.img}})]),_c('div',{staticClass:"control__message-content"},[_vm._v(" "+_vm._s(_vm.item.graph)+" "),('items' in _vm.item)?_c('span',[_vm._v(_vm._s(_vm.item.name))]):_c('span',{staticClass:"element__link",on:{"click":function($event){return _vm.beforeMove({path:_vm.item.path})}}},[_vm._v(_vm._s(_vm.item.name))]),_vm._v(" "+_vm._s(_vm.item.message.text)+" "),('items' in _vm.item)?_c('div',{staticClass:"element__links"},[(_vm.item.items.length <= 5)?_vm._l((_vm.item.items),function(element,sidx){return _c('span',{key:`msg-${_vm.idx}-s-${sidx}`,staticClass:"mx-1",class:_vm.getItemClass(element),on:{"click":function($event){_vm.beforeMove({path:_vm.getPathFromElement(element), index:_vm.getIndexFromElement(element)})}}},[_vm._v(_vm._s(_vm.getIndexFromElement(element))),_c('span',{staticClass:"transparent"},[_vm._v(",")])])}):[_vm._l((_vm.item.items.slice(0,5)),function(element,sidx){return _c('span',{key:`msg-${_vm.idx}-s-${sidx}`,staticClass:"mx-1",class:_vm.getItemClass(element),on:{"click":function($event){_vm.beforeMove({path:_vm.getPathFromElement(element), index:_vm.getIndexFromElement(element)})}}},[_vm._v(_vm._s(_vm.getIndexFromElement(element))),_c('span',{staticClass:"transparent"},[_vm._v(",")])])}),(_vm.item.extended === false)?_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){_vm.item.extended = true}}},[_vm._v(" ... ")]):_vm._l((_vm.item.items.slice(5, _vm.item.items.length - 1 )),function(element,sidx){return _c('span',{key:`msg-${_vm.idx}-s-${sidx}-extended`,staticClass:"mx-1",class:_vm.getItemClass(element),on:{"click":function($event){_vm.beforeMove({
              path:_vm.getPathFromElement(element),
              index:_vm.getIndexFromElement(element)
            })}}},[_vm._v(_vm._s(_vm.getIndexFromElement(element))),_c('span',{staticClass:"transparent"},[_vm._v(",")])])}),_c('span',{staticClass:"mx-1",class:_vm.getCurrentSelectedChild === _vm.lastControlItem ? 'element__link_selected' : 'element__link',on:{"click":function($event){_vm.beforeMove({
            path:_vm.getPathFromElement(_vm.lastControlItem),
            index:_vm.getIndexFromElement(_vm.lastControlItem)
          })}}},[_vm._v(_vm._s(_vm.getIndexFromElement(_vm.lastControlItem))+" ")])],('items' in _vm.item)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0 ml-2",attrs:{"color":"#DEDEDE","min-width":"20px","max-width":"20px","height":"20px"},on:{"click":_vm.copyListItems}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" mdi-content-copy ")])],1)]}}],null,false,2449250276)},[_vm._v(" Копировать список ")]):_vm._e()],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }