import cloneDeep from "lodash.clonedeep";
// Функция возвращает значение поля которое не используется для отображения в DOM,
// а заполняется из другого свойства
// Например country_name заполняется перед отправкой из свойства country_letter
// Визуально на форме это один input
function getVisibleSibling(field) {
  const correlation = {
    country_name:"country_letter",
    subject_person_name:"subject_name",
    counter_agent_person_name:"counter_agent_name",
    declarant_person_name:"declarant_name",
    dispatch_country_name:"dispatch_country_letter",
    destination_country_name:"destination_country_letter",
    origin_country_name:"origin_country_letter",
    trade_country_name:"trade_country_letter",
    title:"description",
    add_measure_unit_letter:"add_measure_unit_digit",
    identity_doc_code:"identity_doc_name",
    destination_customs_country_letter:"destination_customs_country_letter"
  }
  return field in correlation ? correlation[field] : field
}

function removeFirstFromMap(map) {
  return map.delete(map.keys().next().value)
}

function getFirstKeyFromMap(map) {
  if (map instanceof Map) {
    return map.keys().next().value
  }
  return null
}

function getFirstValueFromMap(map) {
  if (map instanceof Map) {
    const key = getFirstKeyFromMap(map)
    return map.get(key)
  }
  return null
}


function getIndexFromElement(element) {
  return Object.values(element).at(0)
}

function getPathFromElement(element) {
  return Object.keys(element).at(0)
}

async function moveTo({path}) {
  // path - пусть до блока
  // index - индекс элемента в блоке на последнем уровне вложенности
  // ПРИМЕР: ware_shipments.1.wares.1.package_types_json.package_quantity 3
  // ПАРТИЯ (ware_shipments) - 1
  // ТОВАР (wares) - 1
  // БЛОК (package_types_json)
  // ПОЛЕ (package_quantity)
  // ИНДЕКС ЭЛЕМЕНТА В БЛОКЕ 3
  try {
    const map = new Map()
    path.split('.').forEach((element, index, arr) => {
      const nextIndex = index + 1
      const nextElement = Number.parseInt(arr.at(nextIndex))
      if (!Number.isNaN(nextElement)) { // Следующий ключ это чисто
        map.set(element, nextElement)
      } else if (Number.isNaN(Number.parseInt(element))) {
        map.set(element, null)
      }
    })
    await nextTo(map)
  } catch (err) {
    this.$snackbar({
      text: err.message,
      color: "red",
      top: false,
      right: false,
    });
  }
}

async function nextTo(map) {
  const events = await import("@/events/statistics/control");
  const key = getFirstKeyFromMap(map)
  const value = getFirstValueFromMap(map)
  const event = cloneDeep(events[key])
  if (value && ['wares'].includes(key)) {
    event.name = `${event.name}-${value}`
  }
  event.trigger({path: map});
}

function toggleErrorState(element, type) {
  const BORDER__ERROR = "2px solid red";
  const BORDER_DEFAULT = "1px solid rgba(0, 0, 0, 0.8)";
  const INPUT_DISABLED_CLASS = "v-input--is-disabled";
  const BLOCK_ERROR_CLASS = "highlight__error"
  const TIMEOUT = 3500

  if (type) {
    if (type === "textarea") {
      element.style.border = BORDER__ERROR;
      setTimeout(() => {
        element.style.border = BORDER_DEFAULT;
      }, TIMEOUT);
    } else if (type === "block") {
      element.classList.add(BLOCK_ERROR_CLASS)
      setTimeout(() => {
        element.classList.remove(BLOCK_ERROR_CLASS)
      }, TIMEOUT);
    }
  } else {
    if (
      element &&
      !element.classList.contains("error--text") &&
      !element.classList.contains("v-input--has-state")
    ) {
      let disabledDefault;
      element.classList.add("v-input--has-state", "error--text");
      if (element.classList.contains(INPUT_DISABLED_CLASS)) {
        disabledDefault = true;
        element.classList.add("v-input--has-state", "error--text");
        element.classList.remove(INPUT_DISABLED_CLASS);
      }
      const icon = element.querySelector(".v-icon");
      icon ? icon.classList.add("error--text") : false;
      setTimeout(() => {
        element.classList.remove("v-input--has-state", "error--text");
        icon ? icon.classList.remove("error--text") : false;
        disabledDefault ? element.classList.add(INPUT_DISABLED_CLASS) : false;
      }, TIMEOUT);
    }
  }
}

function getScrollOffset() {
  const pinned = document.getElementById("pinned-block");
  const pinnedHeight = pinned?.getBoundingClientRect()?.height || 0;
  return ((window.innerHeight - pinnedHeight) / 3 + pinnedHeight) * -1;
}

function getBlocksFromPath(path) {
  return path instanceof Map ? Array.from(path.keys()) : path.split('.')
}

function getFieldFromBlocks(blocks) {
  return blocks[blocks.length - 1];
}

function isForcedScroll(element, offset){
  return element?.getBoundingClientRect()?.top < Math.abs(offset)
}


function getTargetElementFromRefs(key, refs) {
  const elementUsual = refs[key];
  const customElement = refs[`${key}__custom`]; // Для полей где есть ввод произвольного значения
  const defaultElement = refs[`${key}__default`]; // Для кода корректировок
  return [elementUsual, customElement, defaultElement].find(
    (element) => {
      return element?.$el?.style?.display !== "none";
    }
  );
}

function highlightElement({element, scroll, offset, selector = "input"}) {
  const selectedElement = element.$el.querySelector(selector)
  if (!scroll) {
    return toggleErrorState(element.$el);
  }
  this.$scrollTo(element.$el, 200, {
    force: isForcedScroll(element.$el, offset),
    offset,
    onDone: () => {
      enableAndFocus(selectedElement)
      this.$nextTick(() => {
        toggleErrorState(element.$el);
      });
    },
  });
}

function highlightDescription({element, scroll, offset}) {
  // Описание товара
  const description = element.$el;
  const wrapper = description.querySelector(
    ".goods-description__text-wrapper"
  );
  if (wrapper) {
    if (!scroll) {
      const txt = wrapper.querySelector(".v-input");
      return toggleErrorState(txt, "textarea");
    }
    this.$scrollTo(wrapper, 100, {
      force: isForcedScroll(wrapper, offset),
      offset,
      onDone: () =>
        this.$nextTick(() => {
          const textarea = wrapper.querySelector("textarea");
          textarea.focus();
          this.$nextTick(() => {
            const txt = wrapper.querySelector(".v-input");
            toggleErrorState(txt, "textarea");
          });
        }),
    });
  }
}

function enableAndFocus(htmlInput){
  if(htmlInput.disabled){
    htmlInput.disabled = false
    htmlInput.focus()
    htmlInput.disabled = true
  }
  htmlInput.focus()
}
function highlightCustomInput({element, scroll, offset}) {
  const [children] = element.$el.children;
  const input = children.querySelector("input");
  if (input) {
    if (!scroll) {
      return toggleErrorState(children);
    }
    this.$nextTick(() => {
      this.$scrollTo(input, 200, {
        force: isForcedScroll(input, offset),
        offset,
        onDone: () => {
          enableAndFocus(input)
          this.$nextTick(() => {
            toggleErrorState(children);
          });
        },
      });
    })
  }
}

function highlightBlock({element, offset}) {
  this.$scrollTo(element, 200, {
    force: isForcedScroll(element, offset),
    offset,
    onDone: () => {
      toggleErrorState(element, "block");
    },
  });
}

function highlightField({path, scroll = true}) {
  if(!path) return
  const blocks = getBlocksFromPath(path)
  const field = getFieldFromBlocks(blocks)
  const key = getVisibleSibling(field);
  if (!(key in this.$refs)) return console.warn("Не найдено поле для позиционирования [C2]")
  const element = getTargetElementFromRefs(key, this.$refs)
  const offset = getScrollOffset();
  if (element.$el && element.$el.classList.contains("v-input") && !element.$el.classList.contains("v-textarea")) {
    highlightElement.call(this, {element, scroll, offset})
  }else if(element.$el && element.$el.classList.contains("v-textarea")){
    highlightElement.call(this, {element, scroll, offset, selector:"textarea"})
  } else if (element.$el && element.$el.children) {
    if (key === "description") {
      highlightDescription.call(this, {element, scroll, offset})
    } else {
      highlightCustomInput.call(this, {element, scroll, offset})
    }
  } else {
    highlightBlock.call(this, {element, offset})
  }
}

function wareHighlight({ el, path }) {
  const [wrapper] = el.children;
  if (wrapper.classList.contains("transportation-box__header_close")) {
    const [toggle] = wrapper.children;
    const clickEvent = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: false,
    });
    toggle.dispatchEvent(clickEvent);
    this.waresControlPath = {path}
  } else {
    this.waresControlPath = {path}
  }
}

function highlightTransportItemField(path){
  if (getFirstValueFromMap(path) - 1 === this.index) {
    this.$nextTick(() => {
      removeFirstFromMap(path)
      const realpath = getFirstKeyFromMap(path)
      highlightField.call(this, {path: realpath})
      if (realpath === 'graph_18') {
        this.$nextTick(() => {
          toggleErrorState(this.$refs.graph_21, "block")
        })
      }

    })
  }
}

function isTransportError({path}){
  return path.has("transports_json")
}

function isTransportCommonError({path}){
  return Object.keys(Object.fromEntries(path)).length === 1 && path.has("transports_json")
}

function isDestinationCustomsError({path}){
  return path.has("destination_customs_json")
}

function isDestinationCustomsCommonError({path}){
  return Object.keys(Object.fromEntries(path)).length === 1 && path.has("destination_customs_json")
}


export {
  highlightField,
  getScrollOffset,
  moveTo,
  nextTo,
  removeFirstFromMap,
  getFirstKeyFromMap,
  getFirstValueFromMap,
  getPathFromElement,
  getIndexFromElement,
  toggleErrorState,
  wareHighlight,
  highlightTransportItemField,
  isTransportError,
  isTransportCommonError,
  isDestinationCustomsError,
  isDestinationCustomsCommonError
};

