<template>
  <v-col
    rounded
    class="py-0 px-0 control__block"
  >
    <div class="control__body pa-3">
      <div class="d-flex flex-wrap align-center justify-space-between flex-grow-0">
        <div
          v-for="(type, index) of types"
          :key="`type-${index}`"
          style="font-size: 13px"
        >
          <div
            class="cursor-pointer mr-3 d-flex mb-3 mb-md-0"
            @click="setType(index)"
          >
            <img
              :src="type.image"
              class="mr-1"
            >
            <span
              :class="{
                underline: isUnderlined(index),
              }"
            >{{ type.title }}: {{ countTypeErrors[type.name] }}</span>
          </div>
        </div>
        <div class="d-none d-md-flex flex-grow-1 justify-end">
          <div>
            <slot name="action" />
          </div>

          <v-btn
            min-width="36px"
            max-width="36px"
            height="36px"
            color="#DEDEDE"
            class="ml-2 px-0"
            tabindex="-1"
            @click="togglePin"
          >
            <v-icon>
              {{ pinned.includes(pinName) ? "mdi-pin-off" : "mdi-pin" }}
            </v-icon>
          </v-btn>
        </div>
      </div>
      <v-alert
        v-if="notification"
        border="left"
        color="green"
        dense
        text
        type="success"
        class="control__alert py-0"
      >
        Контроль пройден успешно
      </v-alert>
      <div
        v-if="pinned.includes(pinName) && !show && currentPinnedControl"
        class="d-flex align-center justify-space-between my-2"
      >
        <control-item
          :item="currentPinnedControl"
          :idx="0"
          :selected="selectedControlItem"
          class="mb-0"
          @select="selectControlItem"
        />
        <div>
          <v-btn
            min-width="36px"
            max-width="36px"
            height="36px"
            color="#DEDEDE"
            class="ml-2 px-0"
            @click="switchControl('prev')"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            min-width="36px"
            max-width="36px"
            height="36px"
            color="#DEDEDE"
            class="ml-2 px-0"
            @click="switchControl('next')"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
      <v-col
        v-show="show"
        class="control__wrapper pa-0"
        style="z-index:3"
        :class="{
          absolute: !pinned.includes(pinName),
          'mx-0': pinned.includes(pinName),
        }"
      >
        <div
          class="control__modal rounded-md"
          :class="{
            'pa-3': !pinned.includes(pinName),
          }"
        >
          <div
            :class="{
              'control__modal-content_pinned': pinned.includes(pinName),
              'control__modal-content': !pinned.includes(pinName),
            }"
          >
            <div
              v-if="!filteredControl.length"
              class="text-center"
            >
              {{ types[type].title }}: cписок пуст
            </div>
            <div
              v-for="(item, idx) in filteredControl"
              :key="`msg-${idx}`"
            >
              <control-item
                :item="item"
                :idx="idx"
                :selected="selectedControlItem"
                @select="selectControlItem"
              />
            </div>
          </div>

          <div class="d-flex justify-center align-center">
            <v-icon
              color="white"
              @click="show = false"
            >
              mdi-chevron-up
            </v-icon>
          </div>
        </div>
      </v-col>
    </div>
  </v-col>
</template>
<script>
import { mapGetters } from "vuex";
import {
  toggleControlsPanel,
  toggleControlsNotification,
} from "@/events/common";
import ControlItem from "./document-control-item.vue";
import { moveTo, getPathFromElement } from "@/helpers/control";
export default {
  components: {
    ControlItem,
  },
  props: {
    source: {
      type: String,
      required: true,
      default: () => null,
    },
    pinName: {
      type: String,
      required: true,
      default: () => "controls",
    },
  },
  data: () => ({
    notification: false,
    show: false,
    control: [],
    currentControlIndex: 0,
    selectedControlItem: null,
    type: 0,
    types: [
      {
        name: "error",
        title: "Ошибки",
        image: require("@/assets/img/error.svg"),
      },
      {
        name: "warning",
        title: "Предупреждения",
        image: require("@/assets/img/warning.svg"),
      },
      {
        name: "info",
        title: "Информация",
        image: require("@/assets/img/info.svg"),
      },
    ],
  }),
  computed: {
    ...mapGetters({
      pinned: "ui/getPinned",
    }),
    requests() {
      const [module] = this.source.toString().split("/");
      return this.$store.getters(`${module}/getRequests`) ?? null;
    },
    _source() {
      if (this.source) {
        return this.$store.getters[this.source] || null;
      }
      return null;
    },
    currentPinnedControl() {
      return this.control[this.currentControlIndex] || null;
    },
    countControlErrors() {
      return this.control.length;
    },
    filteredControl() {
      const type = this.types[this.type].name;
      return this.control.filter((i) => i.message.type === type);
    },
    countTypeErrors() {
      const result = {};
      this.control.forEach((element) => {
        const type = element.message.type
        type && type in result ? ++result[type] : (result[type] = 1);
      });
      if (Object.keys(result).length < this.types.length) {
        const exist = Object.keys(result);
        this.types
          .map((i) => i.name)
          .filter((i) => !exist.includes(i))
          .forEach((key) => {
            result[key] = 0;
          });
      }
      return result;
    },
  },
  watch: {
    _source: {
      handler(nv) {
        if (Array.isArray(nv) && nv.length) {
          this.currentControlIndex = 0;
          this.control = nv.map(item => {
            return {
              ...item,
              extended:false,
              img: require(`@/assets/img/${item.message.type}.svg`) || null,
            }
          })
          if (this.control && !this.filteredControl.length) {
            this.setFullfilledType();
          }
        } else {
          this.control = [];
          this.show ? (this.show = false) : false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    toggleControlsPanel.subscribe(this.toggleExpansion);
    toggleControlsNotification.subscribe(this.toggleNotification);
  },
  beforeDestroy() {
    toggleControlsPanel.unsubscribe();
    toggleControlsNotification.unsubscribe();
  },
  methods: {
    moveTo,
    toggleExpansion(value = false) {
      this.show = value;
    },
    toggleNotification() {
      this.notification = true;
      setTimeout(() => {
        this.notification = false;
      }, 3000);
    },
    selectControlItem(item) {
      this.selectedControlItem = item;
    },
    setFullfilledType() {
      // Выбираем тип ошибки
      const [first] = this.control;
      const typeName = first.message.type
      this.type = this.types.findIndex((i) => i.name === typeName);
    },
    isUnderlined(index) {
      return this.show ? index !== this.type : true;
    },
    switchControl(action) {
      if (action === "prev") {
        this.currentControlIndex - 1 < 0
          ? (this.currentControlIndex = this.control.length - 1)
          : --this.currentControlIndex;
      } else if (action === "next") {
        this.currentControlIndex + 1 < this.control.length
          ? ++this.currentControlIndex
          : (this.currentControlIndex = 0);
      }
      const {path, items} = this.control[this.currentControlIndex];

      if(path){
        this.moveTo({ path })
      }else if(Array.isArray(items) && items.length){
        const [element] = items
        this.moveTo({ path: getPathFromElement(element) })
      }

    },
    setType(index) {
      if (index === this.type && this.show) {
        return (this.show = false);
      }
      this.type = index;
      !this.show ? (this.show = true) : false;
    },
    togglePin() {
      this.$store.commit("ui/TOGGLE_PIN", this.pinName);
    },
  },
};
</script>
<style scoped>
.control__block {
  position: relative;
}
.control__body {
  background-color: #333333;
  font-size: 14px;
  border-radius: 5px;
  height: 100%;
  color: #fff;
}
.absolute {
  position: absolute;
  z-index: 2;
  left: 0;
}
.control__wrapper {
  margin: 12px 0;
  width: 100%;
}
.control__modal {
  background-color: #333333;
  border-radius: 0 0 5px 5px;
}
.control__modal-content {
  overflow-y: scroll;
  height: 400px;
}
.control__modal-content_pinned {
  overflow-y: scroll;
  height: 120px;
}
.control__alert {
  transform: translateY(10px);
  font-size: 13px;
}
.control__message {
  display: flex;
  align-items: center;
  margin: 0 45px 20px 0;
}
.control__message-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.control__message-icon img {
  width: 20px;
}
.control__message-content {
  margin: 0 0 0 10px;
}
.cursor-pointer {
  cursor: pointer;
}
.element__link {
  border-bottom: 1px solid white;
  cursor: pointer;
}
.underline {
  border-bottom: 1px solid white;
}
</style>
