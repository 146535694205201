export function getBlockNameFromRequest({res,err, payload, module}){
  try{
    if(!module) throw Error('Не указан тип модуля')
    const {getBlockName} = require(`@/models/${module}`) 
    if(payload){
      const keys = Object.keys(payload)
      return getBlockName(keys)
    }else{
      const payloadString = res ? res?.config?.data : err?.response?.config?.data
      if(payloadString){
        const payload = JSON.parse(payloadString)
        const data = Array.isArray(payload) ? payload[0] : payload 
        const keys = Object.keys(data)
        return getBlockName(keys)
      }
      return null
    }
  }catch(e){
    return e
  }  
 
  
}