import { setChangesTracker } from "@/helpers/inputs";
import { convertEmptyStringsToNull } from "@/helpers/objects";
import { getBlockNameFromRequest } from '@/models'

export default {
  inject:['rootNamespace'],
  data: () => ({
    hasChanges: false, // определяет что были какие-то изменения в значениях
    block_inputs: [], // все инпуты блока
    switches:[], // Все переключатели
    // block_id: "" должен определяться в компоненте -> data
    current_block: {}, // текущий блок
    fields_been_visited: false, // определяет что пользователь активировал какое-то поле
    is_goods_item: false, // определяет что блок является товаром
    processing:false,
  }),
  mounted() {
    // Привязка внутри компонента тк отрисовываются по условию
    if(!this.is_goods_item){
      this.setEventListeners()
    }
  },
  beforeDestroy() {
    this.destroyEventListeners()
  },
  methods: {
    convertEmptyStringsToNull: convertEmptyStringsToNull,
    isDirty(){
      return this.hasChanges && this.fields_been_visited
    },
    setEventListeners(){
      if (this.checkRequiredElements() === false) return;
      // Отслеживаем изменения v-autocomplete
      // тк. hidden inputs не триггерят события
      setChangesTracker(`#${this.block_id} .v-autocomplete input[type=hidden]`);
      this.setInputChangesTracker();  
    },
    updateEventListeners(){
      this.destroyEventListeners()
      this.setEventListeners()
    },
    destroyEventListeners(){
      document.removeEventListener("focusin", this.triggerOnFocus);
      this._stopObserveInputs()
      this._stopObserveSwitches()
    },
    setHasChangesFalse() {
      this.hasChanges = false;
    },
    setInputChangesTracker() {
      this.current_block = document.getElementById(this.block_id);
      document.addEventListener("focusin", this.triggerOnFocus); // Событие  на смену активного блока
      this._observeInputs()
      this._observeSwitches()
    },
    setEditableWare(event){
      if(this._focusedFieldBelongsToAnotherWare(event)){
        this.$store.commit(`${this.rootNamespace}/SET_EDITABLE_WARE`,{id:this.info.id, index:this.index})
      }
    },
    triggerOnChange() {
      if (this.fields_been_visited) this.hasChanges = true;
    },
    triggerOnFocus(event) {
      if(this.processing) return false; 
      if(this.is_goods_item){
        event.stopPropagation()
        this.setEditableWare(event)
      }
      if (this._isNotAllowedTarget(event)) {
        return false;
      }
      if (this._userLeftUpdatedBlock(event)) {
        const globalPromise = new Promise((resolve, reject) => {
          this.processing = true
          const response = this.uploadData()
          if(typeof response === 'object' && response instanceof Promise){
            const currentModuleName = this._currentModule() // Название текущего модуля (документа)
            response.then((res)=> {
              this._updatedSuccessfully(res, currentModuleName)
              resolve()
            }).catch((err) =>{
              this.processing = false
              this._updateFailed(err, currentModuleName)
              reject()
            });
          } 
        })
        this._pushRequest(globalPromise)
        globalPromise.finally(() => this._removeRequest(globalPromise))
      }
    },
    fieldsBeenVisitedByUser() {
      if (!this.fields_been_visited) this.fields_been_visited = true;
    },
    // Для переключателей
    toggledByUser(){
      if (!this.fields_been_visited) this.fields_been_visited = true;
      if (!this.hasChanges) this.hasChanges = true;
    },
    checkRequiredElements() {
      if (this.block_id === "") {
        this.$snackbar({text:"НЕ УКАЗАН BLOCK_ID",color:"red", top:false, right: false});
        return false;
      }
    },
    resetTriggers(){
      this.fields_been_visited = false
      this.hasChanges = false
    },
    _isNotAllowedTarget(event){
      // Если действие вызвано кликом по выпадающему списку
      // или элементу внутри класса "prevent-trigger-update" (обычно для модалок и тд)
      // то НЕ триггерить событие
      return event.target.classList.contains("v-list") ||
        event.target.classList.contains("v-list-item") ||
        event.target.classList.contains("v-list-item__content") ||
        event.target.classList.contains("v-list-item__title") ||
        event.target.closest('.prevent-trigger-update') !== null
    },
    _updateFailed(err, module){
      let name;
      const {validation, payload} = err // Результат валидации перед отправкой на сервер
      if(validation){
        name = getBlockNameFromRequest({payload, module}) // Название блока
        const error = Array.isArray(validation) ? validation.join("\r\n") :validation // Ошибки с новой строки полученные при валидации
        this.$snackbar({text:`Ошибка [${name}]:\r\n ${error}`,color:"red", top:false, right: false, timeout:5000});
      }else{
        name = getBlockNameFromRequest({err, module})
        this.$snackbar({text:`Ошибка [${name}]`,color:"red", top:false, right: false});
      }
    },
    _updatedSuccessfully(res, module){
      const name = getBlockNameFromRequest({res, module}) // Название блока
      this.hasChanges = false
      this.fields_been_visited = false
      this.processing = false
      this.$snackbar({text: name ? `Oбновлено [${name}]` :"Обновлено",color:"green", top:false, right: false});
    },
    _userLeftUpdatedBlock(event){
      // Если пользователь вышел из блока +
      // Если пользователь ранее зашел в какой-то инпут +
      // если были изменения
      return !this.current_block.contains(event.target) &&
      this.hasChanges &&
      this.fields_been_visited
    },
    _focusedFieldBelongsToAnotherWare(event){
      return this.is_goods_item
        && this.current_block.contains(event.target)
        && this.info.id !== null
        && this.$store.getters[`${this.rootNamespace}/getEditableWare`]['id'] !== this.info.id
    },
    _observeSwitches(){
      const switches = document.getElementById(this.block_id).querySelectorAll('.v-input--switch');
      this.switches = [...switches]
      if(this.switches.length){
        this.switches.forEach((el) => {
          el.addEventListener("click", this.toggledByUser);
        });
      }
    },
    _stopObserveSwitches(){
      this.switches.forEach((switchElement) =>{
        switchElement.removeEventListener("click", this.toggledByUser)
      })
    },
    _observeInputs(){
      const collection = document.getElementById(this.block_id).querySelectorAll('input, textarea');
      this.block_inputs = [...collection];
      this.block_inputs.forEach((el) => {
        el.addEventListener("change", this.triggerOnChange);
        el.addEventListener("focusin", this.fieldsBeenVisitedByUser);
      });
    },
    _stopObserveInputs(){
      this.block_inputs.forEach((el) => {
        el.removeEventListener("change", this.triggerOnChange);
        el.removeEventListener("focusin", this.fieldsBeenVisitedByUser);
      });
    },
    _currentModule(){
      return this.$route.path.split('/')[1] // первый URI -- например /statistics (без слеша)
    },
    _pushRequest(promise){
      const module = this._currentModule()
      this.$store.commit(`${module}/ADD_REQUEST`, promise)
    },
    _removeRequest(promise){ // Удаление промиса из списка промисов
      const module = this._currentModule()
      setTimeout(() => { // Задержка в 2с чтобы потенциальные обработчики выполнились после промиса
        this.$store.commit(`${module}/REMOVE_REQUEST`, promise)
      }, 2000);
    }
    // uploadData() должен определяться в компоненте
    //setDefaultEditedGood() должен определяться в компоненте
  },
};
