export default {
  counter_agent_inn: {
    maxLength:20
  },
  counter_agent_name: {
    maxLength:120
  },
  counter_agent_person_name: {
    maxLength:150
  },
  counter_agent_json: {
    postal_index: {
      maxLength:9
    },
    region: {
      maxLength:50
    },
    city: {
      maxLength:35
    },
    street_house: {
      maxLength:50
    },
  }
}