
// Функция для получения названия блока по уникальному параметру который характерен для блока
export function getBlockName(keys){
    
  if(keys.includes("client_name")){
    return "Клиент"
  }else if(keys.includes("out_number")){
    return "О декларации"
  }
  else if(keys.includes("guarantee_number")){
    return "О гарантии"
  }
  else if(keys.includes("declarant_json")){
    return "Декларант"
  }
  else if(keys.includes("filler_json")){
    return "Лицо, сформ. свидетельство"
  }
  else if(keys.includes("payer_json")){
    return "Плательщик"
  }
  else if(keys.includes("reg_num_a")){
    return "Таможенная информация"
  }
}
     
      
  
  
      