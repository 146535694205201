
// Функция для получения названия блока по уникальному параметру который характерен для блока
export function getBlockName(keys){
  if(keys.includes("dispatch_country_letter")){
    return "Страна отправления"
  }else if(keys.includes("about_wares")){
    return "О товаре"
  }else if(keys.includes("declarant_branch_unp")){
    return "Декларант"
  }else if(keys.includes("filler_json")){
    return "Место и дата"
  }else if(keys.includes("mark_name")){
    return "Товары"
  }else if(keys.includes("doc_date")){
    return "Документы"
  }else if(keys.includes("sum")){
    return " Подробности подсчета"
  }else{
    return "Основная информация"
  }
}
     
      
  
  
      