
// Функция для получения названия блока по уникальному параметру который характерен для блока
export function getBlockName(keys){
  if(keys.includes("subject_json")){
    return "Резидент"
  }
  else if(keys.includes("counter_agent_json")){
    return "Контрагент"
  }
  else if(keys.includes("out_number")){
    return "О декларации"
  }
  else if(keys.includes("deal_details_json")){
    return "Сделка"
  }
  else if(keys.includes("filler_json")){
    return "Декларант"
  }
  else if(keys.includes("reg_num_a")){
    return "Регистрация"
  }
  else if(keys.includes("type_declaration_json")){
    return "Основная информация"
  }
  else if(keys.includes("tn_ved")){
    return "Товар"
  }
  else if(keys.includes("ware_id")){
    return "Артикул"
  }else {
    return ""
  }
}