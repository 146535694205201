export default {
  subject_unp: "УНП",
  subject_name: "Наименование организации",
  subject_person_name: "Наименование ИП",
  subject_branch_unp: "УНП филиала",
  subject_branch_name: "Наименование филиала",
  subject_json: {
    country_name: "Страна",
    country_letter: "Страна",
    postal_index: "Индекс",
    region: "Область/Район",
    city: "Город/Деревня",
    street_house: "Адрес",
    phone: "Телефон",
    customs_code: "Код тамож. органа",
    branch_country_name: "Страна филиала",
    branch_country_letter: "Страна филиала",
    branch_postal_index: "Индекс филиала",
    branch_region: "Область филиала",
    branch_city: "Город филиала",
    branch_street_house: "Адрес филиала",
  }
}