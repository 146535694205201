// Передает $ref на компонент при создании асинхронного компонента
// следующему обработчику

export async function getReferenceAsync(component){
  if(!component) return;
  await this.$nextTick()
  const {resolve, reject} = this.asyncDecision
  component ? resolve(component) : reject(null)
}

// Выносит функции resolve и reject наружу,
// чтобы можно было завершить промис из любого места
export function waitAsyncDecision(){
  return new Promise((resolve, reject) => {
    this.asyncDecision = {
      resolve, reject
    }
  })
}

// Вызов функции у асинхронного компонента
export function waitResultOrDie(componentRef){
  return componentRef.waitAsyncDecision()
}