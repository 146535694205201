export const name = 'subject'

export default {
  subject_unp: {
    maxLength:9
  },
  subject_name: {
    maxLength:120
  },
  subject_person_name: {
    maxLength:150
  },
  subject_branch_unp: {
    maxLength:9
  },
  subject_branch_name: {
    maxLength:120
  },
  subject_json: {
    postal_index: {
      maxLength:9
    },
    region: {
      maxLength:50
    },
    city: {
      maxLength:35
    },
    street_house: {
      maxLength:50
    },
    branch_postal_index: {
      maxLength:9
    },
    branch_region: {
      maxLength:50
    },
    branch_city: {
      maxLength:35
    },
    branch_street_house: {
      maxLength:50
    },
  }
}